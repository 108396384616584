import React, { useCallback, useRef, useState } from 'react';

export default function Query() {
  const queryRef = useRef<HTMLTextAreaElement>(null);
  const [status, setStatus] = useState<string>('');
  const [data, setData] = useState<any[][]>();

  const onQuery = useCallback(async () => {
    const query = queryRef.current?.value.trim();
    if (!query) return;

    const res = await fetch("https://zap-1jg.pages.dev/query", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
      },
      "body": JSON.stringify({
        query,
      }),
    });

    if (res.status !== 200) {
      console.error(res);
      setStatus("err " + res.status.toString());
      setData(undefined);
    } else {
      const data = await res.json();
      console.log(data);
      setStatus(data.rows.length + ' rows');
      setData(data.rows);
    }
  }, []);

  return (
    <div>
      <textarea spellCheck={false} cols={80} rows={10} ref={queryRef} />
      <br />
      <button onClick={onQuery}>Query</button>
      &nbsp;
      {status}
      <table>
        {data?.map(row => (
          <tr>{row.map(col => <td>{col}</td>)}</tr>
        ))}
      </table>
    </div>
  );
}
