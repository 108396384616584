import React, { useCallback, useEffect, useState } from 'react';
import type mapboxgl from 'mapbox-gl';

import Map from "./Map";

import './App.css';
import { Listing, fetchListings } from './listing';

const App = React.memo(() => {
	const [bounds, setBounds] = useState<mapboxgl.LngLatBounds>();
	const [listings, setListings] = useState<Listing[]>([]);

	const onBoundsChange = useCallback((bounds: mapboxgl.LngLatBounds) => {
		setBounds((prevBounds: mapboxgl.LngLatBounds | undefined) => {
			if (!prevBounds) return bounds;
			if (prevBounds.toString() === bounds.toString()) return prevBounds;
			return bounds;
		});
		return;
	}, []);

	useEffect(() => {
		console.log('bounds change!', bounds);
		if (!bounds) return;
		fetchListings(bounds).then(listings => {
			console.log(listings);
			setListings(listings)
		});
	}, [bounds]);

	return <div className='app'>
		<Map className='map' listings={listings} onBoundsChange={onBoundsChange} />
	</div>;
});

export default App;