import { useCallback, useEffect, useRef } from "react"
import mapboxgl from "mapbox-gl";

import 'mapbox-gl/dist/mapbox-gl.css'; 
import { Listing } from "./listing";

mapboxgl.accessToken = 'pk.eyJ1IjoiYWx0eGciLCJhIjoiY2xwdDNjZHV3MDhrbTJrb2cxNTJyeDFnbSJ9.ViF-qADoECrcA-r6mKZ4Wg'

export default function Map({
	className,
	listings,
	onBoundsChange,
}: {
	className?: string;
	listings?: Listing[];
	onBoundsChange?: (bounds: mapboxgl.LngLatBounds) => void;
}) {
	const mapRef = useRef<mapboxgl.Map>();

	const onMapIdle = useCallback(() => {
		if (!mapRef.current) return;

		const map = mapRef.current;
		onBoundsChange?.(map.getBounds())
	}, [onBoundsChange]);

	const setContainerRef = useCallback((node: HTMLElement | null) => {
		if (!node) return;
		const map = mapRef.current = new mapboxgl.Map({
			container: node,
			center: [-123.12, 49.28],
			zoom: 12,
		});
		map.on('idle', onMapIdle);
		map.on('style.load', () => {
			// map.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
		});
	}, [onMapIdle]);

	useEffect(() => {
		const map = mapRef.current;
		if (!map) return;
		if (!map.isStyleLoaded()) return;
		if (!listings?.length) return;

		if (!map.getSource('listings')) {
			map.addSource('listings', {
				type: 'geojson',
				cluster: true,
				clusterRadius: 10,
				clusterMaxZoom: 20,
				data: {
					type: 'FeatureCollection',
					features: listings.map(listing => ({
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [+listing.longitude, +listing.latitude],
						},
						properties: {
							mlsNumber: listing.mlsNumber,
							listingPrice: listing.listingPrice,
						}
					})),
				}
			});

			map.addLayer({
				id: 'listings',
				type: 'circle',
				source: 'listings',
				filter: ['!=', 'cluster', true],
				paint: {
					"circle-color": 'red',
					"circle-radius": 4,
					"circle-stroke-width": 1.2,
					"circle-stroke-color": 'white',
					"circle-stroke-opacity": 0.8,
				}
			});

			map.addLayer({
				id: 'clusters',
				type: 'circle',
				source: 'listings',
				filter: ['==', 'cluster', true],
				paint: {
					"circle-color": 'yellow',
					"circle-radius": 8,
					"circle-stroke-width": 1.2,
					"circle-stroke-color": 'white',
					"circle-stroke-opacity": 0.8,
				}
			});

			map.addLayer({
				id: 'clusterLabels',
				type: 'symbol',
				source: 'listings', 
				filter: ['==', 'cluster', true],
				layout: {
					"text-field": ['get', 'point_count_abbreviated'],
					// "text-font": ['DIN Pro'],
					"text-size": 8,
				}
			})
		} else {
			(map.getSource('listings') as mapboxgl.GeoJSONSource).setData({
				type: 'FeatureCollection',
				features: listings.map(listing => ({
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [+listing.longitude, +listing.latitude],
					},
					properties: {
						mlsNumber: listing.mlsNumber,
						listingPrice: listing.listingPrice,
					}
				})),
			})
		}

	}, [listings]);

	return <div className={className} ref={setContainerRef}></div>
}