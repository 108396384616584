import type mapboxgl from "mapbox-gl";

export interface Listing {
	mlsNumber: string;	
	latitude: number;
	longitude: number;
	listingDate: string;
	condoName: string;
	streetAddress: string;
	listingPrice: number;
	description: string;
	houseAge: number;
	bedroomCount: number;
	bathroomCount: number;
	houseSize: number;
	sellingAgent: string;
	sellingBrokerage: string;
	unitNumber: string;
	lotSize: number;
	openHouse: string;
	photos: string;
}

const FIELDS: (keyof Listing)[] = ['mlsNumber', 'latitude', 'longitude', 'listingDate', 'condoName', 'streetAddress', 'listingPrice', 'description', 'houseAge', 'bedroomCount', 'bathroomCount', 'houseSize', 'sellingAgent', 'sellingBrokerage', 'unitNumber', 'lotSize', 'openHouse', 'photos'];

export async function fetchListings(bounds: mapboxgl.LngLatBounds) {
	const res = await fetch("https://zap-1jg.pages.dev/query", {
		"method": "POST",
		"headers": {
			"content-type": "application/json",
		},
		"body": JSON.stringify({
			query: `SELECT ${FIELDS.join(',')} FROM real_active_2018.testTable WHERE longitude BETWEEN ${bounds.getWest()} AND ${bounds.getEast()} AND latitude BETWEEN ${bounds.getSouth()} AND ${bounds.getNorth()} ORDER BY entryDate DESC LIMIT 200`,
		}),
	});

	const listings = (await res.json()).rows.map((row: Listing[keyof Listing][][]) => {
		const o: Listing = {} as Listing;
		row.forEach((col, i) => {
			// @ts-expect-error
			o[FIELDS[i]] = col;
		})
		return o;
	});

	return listings;
}
